<template>
  <el-dialog
    title="Delete Customer"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>
          The action cannot be undone. Are you sure you want to delete account,
          <strong class="text-grey-primary text-bold"
            >{{ customerName }}?</strong
          >
        </p>
      </el-col>
    </el-row>
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Reason" prop="reason" :rules="validateField()">
            <el-checkbox-group v-model="form.reason" @change="setReason">
              <el-checkbox
                v-for="(reason, index) in reasons"
                :key="index"
                :label="reason"
              />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="form.reason[0] === 'Other'"
            label="Enter your reason here"
            prop="other_reason"
            :rules="validateField()"
          >
            <el-input type="textarea" rows="2" v-model="form.other_reason" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="danger"
        :loading="deleting"
        :disabled="!enableDelete"
        @click="deleteCustomer"
        >Delete</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerDelete",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
      form: {
        email: "",
        reason: [],
        other_reason: "",
      },
      reasons: [
        "The app no longer serves my needs",
        "It's too expensive",
        "I'm concerned about my data",
        "I had troubles setting up my services",
        "I was just testing the app out",
        "Email needed to set up coporate account",
        "Other",
      ],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerName() {
      return this.formatName(this.customer.name);
    },
    enableDelete() {
      const { email, reason } = this.form;
      return email === this.customer.email?.toLowerCase() && reason.length > 0;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.email = this.stringTrim(this.customer.email);
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.form.email = "";
    },
    setReason(reason) {
      this.form.reason = reason.length > 0 ? [reason.pop()] : [];
    },
    deleteCustomer() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.deleting = true;

        const email = this.form.email;
        const payload = {
          reason:
            this.form.reason === "Other"
              ? this.form.other_reason
              : this.form.reason[0],
        };
        customer
          .delete(email, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.deleting = false;
              this.$router.push({ name: "customers.index" });
              this.closeEvent();
            } else {
              this.deleting = false;
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.deleting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-checkbox {
  width: 100%;
}
</style>
